.ca-groups {
  &-table {
    &-logo {
      display: block;
      height: 24px;
      width: 48px;
      object-fit: cover;
    }
    &-logo-name-con {
      @include flexbox(row, center, flex-start);
      gap: $spacer;
    }
  }
}
