$nav-width: 244px;
$builder-width: 400px;

/*
 *  Main Layout
 */

.cadmin-layout {
  &-root {
    height: 100vh;
    overflow: auto;
  }
  &-header {
    z-index: 1;
  }
  &-nav {
    height: 100vh;
    width: 0;
    position: sticky;
    top: 0;
    float: left;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }
  &-builder {
    height: 100vh;
    width: 0;
    position: sticky;
    top: 0;
    float: right;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }
  &-content {
    margin-left: $nav-width;
    overflow: auto;
    min-height: 100vh;
    transition: margin 300ms;
    &-launch-warning {
      @include flexbox(row, center, center);
      background-color: lighten($color-accent-yellow, 45%);
      border: 1px solid $color-accent-yellow;
      border-width: 1px 0;
      height: 45px;
      p {
        margin-right: $spacer;
      }
      .btn {
        margin-left: $spacer;
      }
    }
  }
  &-content-padder {
    padding: ($spacer * 4) ($spacer * 4);
    padding-bottom: $spacer * 8;
    min-width: map-get($breakpoints, "xl") - $nav-width;
  }
}

/*
 *  Builder
 */

.cadmin-layout-root.builder {

  &.builder-closed {
    .cadmin-layout-content {
      margin-right: 0;
    }
  }

  .cadmin-layout-content {
    margin-right: $builder-width;
  }
  .cadmin-layout-content-padder {
    padding: 0;
    min-width: 0;
  }

  .cadmin-builder {
    box-shadow: 0 5px 5px rgba(0,0,0,0.25);
    width: $builder-width;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: $color-gray-ice;
    position: absolute;
    transition: right 300ms;
    right: 0;
    top: 0;

    &.closed {
      right: $builder-width * -1;
    }

    &-tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: $spacer * 2;
      right: 100%;
      background-color: $color-gray-ice;
      cursor: pointer;
      text-transform: uppercase;
      padding: ($spacer * 0.5) ($spacer * 2);
      transform: translate(0%, -100%) rotate(-90deg);
      transform-origin: right bottom;
      box-shadow: 0 0 5px rgba(0,0,0,0.25);

      // cover up part of shadow
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: -5px;
        right: -5px;
        background-color: $color-gray-ice;
        height: 5px;
      }

      svg.icon {
        width: 10px;
        margin-right: calc($spacer / 2);
      }
    }
    &-content {
      flex: 1;
      overflow: auto;
    }

    &-bottom {
      flex-shrink: 0;
      position: sticky;
      bottom: 0;
      background-color: $color-gray-midnight;
      z-index: 2;
    }
  }
}

/*
 *  Float Nav
 */

.cadmin-layout-root.float-nav {
  .cadmin-nav {
    left: ($nav-width * -1) + 1px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.25);
    &-tab {
      display: flex;
      svg.icon {
        transition: transform 300ms;
      }
    }
    &:hover, &.pinned {
      left: 0;
      .cadmin-nav-tab svg.icon {
        transform: rotate(180deg);
      }
    }
  }
  .cadmin-layout-content {
    margin-left: 0;
  }
}

/*
 *  Nav
 */

.cadmin-nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: $color-gray-ice;
  position: relative;
  width: $nav-width;
  transition: left 300ms;

  &-tab {
    display: none;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: $spacer * 2;
    left: 100%;
    background-color: $color-gray-ice;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    text-transform: uppercase;
    transform: rotate(-90deg) translate(-100%, 0px);
    transform-origin: 0 0;
    padding: ($spacer * 0.5) ($spacer * 2);
    box-shadow: 0 0 5px rgba(0,0,0,0.25);

    // cover up part of shadow
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: -5px;
      right: -5px;
      background-color: $color-gray-ice;
      height: 5px;
    }

    svg.icon {
      width: 10px;
      margin-right: calc($spacer / 2);
    }
  }

  .coming-soon {
    &::before {
      display: block;
      content: '';
      position: absolute;
      background: linear-gradient(to right, transparent, $color-gray-ice 50%);
      top: 0; right: 0; bottom: 0; left: 0;
    }
    &::after {
      display: block;
      content: 'Coming Soon';
      position: absolute;
      background: $color-gray-fog;
      color: white;
      top: 50%;
      margin-top: -8px;
      height: 16px;
      line-height: 16px;
      right: $spacer * 1.5;
      padding: 0 calc($spacer / 2);
      font-weight: $cerebri-bold;
      font-size: 9px;
      text-transform: uppercase;
      border-radius: 100px;
    }
  }

  &-top {
    margin: 0 ($spacer * 2);
    border-bottom: 1px solid $color-gray-cloud;
    position: sticky;
    top: 0;
    &-logo {
      max-width: 200px;
      max-height: 50px;
      display: block;
      margin: ($spacer * 2) auto;
    }
  }

  &-menu {
    flex: 1;
    overflow: auto;
    // border: 1px solid red;

    &-group {
      margin: $spacer 0;
      position: relative;
      .cadmin-nav-menu-attn {
        margin-left: $spacer * 0.5;
        background-color: $color-accent-pink;
        color: white;
        font-weight: $cerebri-bold;
        padding: 0 4px;
        border-radius: 5px;
        font-size: 12px;
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: $cerebri-bold;
        text-transform: uppercase;
        padding: ($spacer) 0;
        padding-left: $spacer * 2;
        color: $color-gray-slate;
        position: relative;
        svg.icon {
          width: 22px;
          margin-right: calc($spacer / 2);
        }
      }
      &-children {
        height: 0;
        overflow: hidden;
        transition: height 200ms;
      }
      &-child {
        padding: calc($spacer / 2) 0;
        padding-left: calc($spacer / 2) + 10px;       //
        margin-left: ($spacer * 2) + 11px;        // 10 + 11 + 1 = 22, width of icon
        border-left: 1px solid $color-gray-cloud; //
        font-size: 14px;
        position: relative;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
        display: block;
        color: inherit;
        &:hover {
          text-decoration: none;
          font-weight: $cerebri-bold;
        }
        &.coming-soon {
          &:hover {
            cursor: inherit;
            text-decoration: none;
            font-weight: $cerebri-regular;
          }
        }
        &.active {
          color: $color-accent-blue;
          font-weight: $cerebri-bold;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 3px;
            background-color: $color-accent-blue;
          }
        }
      }
    }

    &-bottom-spacer {
      height: 150px;
    }
  }

  &-bottom {
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    background-color: inherit;
    &-db-btn {
      display: block;
      margin: $spacer * 2;
    }
  }

  &-year-select {
    min-width: 120px;
    display: block;
    background-color: white;
    margin: $spacer ($spacer * 2);

    &-option {
      &-year {
        font-weight: $cerebri-bold;
        line-height: 16px;
      }
      .faint {
        font-size: 12px;
      }
      &:not(.selected) {
        padding: $spacer;
      }
    }
  }

  &.expand-all,
  .cadmin-nav-menu-group.active,
  .cadmin-nav-menu-group:hover {
    .cadmin-nav-menu-group-children {
      height: 90px;
      &.n0 { height:   0px; }
      &.n1 { height:  30px; }
      &.n2 { height:  60px; }
      &.n3 { height:  90px; }
      &.n4 { height: 120px; }
      &.n5 { height: 150px; }
      &.n6 { height: 180px; }
      &.n7 { height: 210px; }
    }
  }
}

/*
 *  Loading
 */

.page-cadmin-loading {

  &-icon-con {
    margin: ($spacer * 8) auto;
    color: $color-text-faint;
    text-align: center;

    svg.icon {
      width: 48px;
    }
  }
}

/*
 *  Header
 */

.ca-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $spacer * 2;
  &:first-child {
    margin-top: $spacer * -4;
  }

  &-title {
    font-size: 23px;
    font-weight: $cerebri-bold;
    margin: 0;
    margin-top: $spacer * 4;
  }
}

.ca-back-crumb + .ca-page-header {
  .ca-page-header-title {
    margin-top: 0;
  }
}
