.ps-bar {
  background-color: $color-gray-midnight;
  & > .widther {
    @include flexbox(row, center, center);
    gap: $spacer ($spacer * 4);
    flex-wrap: wrap;
    padding: ($spacer * 0.75);
    color: $color-gray-slate;
    min-height: 40px;
  }
  &-msg {
    strong {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      color: white;
      & + span {
        padding-left: $spacer;
      }
    }
  }
  &-group {
    @include flexbox(row, center, center);
    gap: $spacer * 1;
    position: relative;
    & + .ps-bar-group {
      // border-left: 1px solid rgba($color-gray-slate, 0.5);
      &:before {
        @include pseudo-el();
        width: 1px;
        height: 100%;
        top: 0;
        left: ($spacer * -2);
        background-color: rgba($color-gray-slate, 0.5);
      }
    }
  }
  &-link {
    @include flexbox(row, center, center);
    color: #00E0E7;
    svg.icon {
      width: 10px;
      margin-left: calc($spacer / 4);
    }
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  &-cta {
    @extend .btn, .xs, .special, .green;
    color: $color-gray-midnight;
    text-transform: uppercase;
    &:hover {
      background: #00B8B7 !important;
      &:before {
        display: none !important;
      }
      color: white !important;
      text-decoration: none;
    }
  }
}


.ps-modal-review {
  &-opts {
    margin-top: $spacer * 2;
  }
  &-opt {
    position: relative;
    margin-top: $spacer;
    & > .radio-button {
      position: absolute;
      top: 50%;
      left: $spacer;
      margin-top: -7.5px;
    }
    & > label {
      display: block;;
      padding: $spacer;
      padding-left: ($spacer * 2) + 15px;
      background-color: $color-gray-ice;
      border: 1px solid $color-gray-ice;
      border-radius: 5px;
    }
    &.checked > label {
      border-color: $color-accent-blue;
      background-color: $color-highlight-blue;
    }
    &-title {
      font-weight: $cerebri-bold;
    }
    &-desc {
      margin: 0;
    }
  }
  &-note {
    margin-top: $spacer !important;
  }
}
