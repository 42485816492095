.ca-tracking {

  .tag {
    font-size: 12px;
    background-color: $color-gray-ice;
    color: $color-text-medium-1;
    padding: 2px 6px;
    border-radius: 100px;
    font-weight: $cerebri-bold;
    display: inline-block;
    text-transform: lowercase;
    &.blue {
      background-color: $color-highlight-blue;
      color: $color-accent-blue;      
    }
    &.yellow {
      background-color: lighten($color-accent-yellow, 45%);
      color: darken($color-accent-yellow, 5%);
    }
    &.red {
      background-color: lighten($color-danger, 50%);
      color: $color-danger;
    }
  }

  .ca-box-body {
    overflow: auto;
  }

  &-table {
    td.status {
      white-space: nowrap;
      .btn:last-child {
        margin-left: calc($spacer / 2);
      }
      &.approved {
        color: $color-accent-green;
        font-weight: $cerebri-bold;
      }
      &.rejected {
        color: $color-danger;
      }
    }
    &-cb-emp-con {
      @include flexbox(row, center, flex-start);
      .checkbox {
        flex-shrink: 0;
      }
      gap: $spacer;
    }
  }

  &-event-select {
    max-width: 260px;
    &-option {
      &.unselected {
        display: block;
        padding: ($spacer) $spacer;
        border-left: 2px solid transparent;
        &:hover {
          border-left-color: $color-accent-blue;
        }
      }
      &.selected:not(.placeholder) {
        font-weight: $cerebri-bold;
      }
    }
  }

}


.ca-vtenew {
  &-review-list {
    list-style: none;
    font-size: 16px;
    margin: ($spacer * 2) ($spacer * 4) 0;
    padding: 0;
    svg.icon {
      width: 12px;
      display: inline-block;
      vertical-align: middle;
      margin-right: calc($spacer / 4);
      stroke-width: 2px;
    }
    li {
      margin-bottom: $spacer;
    }
  }
  &-success {
    text-align: center;
    &-icon {
      width: 120px;
      display: block;
      margin: ($spacer * 2) auto;
    }
    &-h1 {
      margin-top: $spacer * 2;
      justify-content: center;
    }
    &-msg {
      margin: $spacer 0 ($spacer * 4);
    }
    &-notice {
      border: 1px solid $color-accent-blue;
      background-color: $color-highlight-blue;
      padding: $spacer;
      border-radius: 5px;
      margin: $spacer 0 ($spacer * 4);
    }
  }

  &.event-type-vol-opp {
    .ca-vtenew-success {
      &-icon, &-h1 {
        color: $color-accent-orange;
      }
    }
  }
  &.event-type-event {
    .ca-vtenew-success {
      &-icon, &-h1 {
        color: $color-groups-a;
      }
    }
  }
}
