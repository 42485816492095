
/*
 *  Page Employees
 */

.page-cadmin-employees {

  table.employees {
    tr.status-deactivated {
      opacity: 0.6;
      td.status {
        color: $color-red;
      }
    }
    tr.status-pending {
      td.status {
        color: $color-text-faint;
      }
    }
    td .cell-name {
      overflow: hidden;
    }
  }

  svg.icon.spin {
    width: 24px;
    color: $color-gray-fog;
  }

  .dd-status {
    .dd-button {
      padding-right: 24px;
    }
    &-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: $spacer;
    }
    .checkbox {
      margin-right: calc($spacer / 2);
    }
  }

  &-start {
    margin: $spacer * 8;
    text-align: center;

    img {
      width: 98px;
    }
    p {
      color: $color-accent-pink;
      font-weight: $cerebri-bold;
      font-size: 21px;
      margin: $spacer * 2;
    }
  }

  &-scim-status-badge {
    // margin-right: calc($spacer / 2);
    border-radius: 100px;
    padding: calc($spacer / 4) calc($spacer / 2);
    font-size: 11px;
    color: white;

    &.manual {
      background-color: $color-danger;
    }
    &.scim {
      background-color: $color-accent-green;
    }
  }
}






/*
 *  Page Employee
 */

.ca-page-emp {
  &-badge {
    font-size: 11px;
    text-transform: uppercase;
    padding: 3px 6px;
    border-radius: 100px;
    color: white;
    display: inline-block;
    &.green { background-color: $color-accent-green; }
    &.danger { background-color: $color-danger; }
    &.gray { background-color: $color-gray-fog; }
  }
}






/*
 *  Page Employees Upload
 */

.ca-page-empup {

  &-step-instr {
    text-align: center;
    // margin-top: $spacer * -2;
    margin-bottom: $spacer * 4;
  }

  &-steps {
    @include flexbox(row, center, center);
    gap: $spacer * 4;
    margin: ($spacer * 4) 0;
    &-step {
      @include flexbox(column, center, center);
      gap: $spacer * 0.5;
      color: $color-gray-slate;
      padding-bottom: $spacer;
      border-bottom: 2px solid transparent;
      &-check {
        width: 18px;
      }
      &-num {
        text-transform: uppercase;
        font-size: 12px;
      }
      &-label {
        font-weight: $cerebri-heavy;
      }
      &.complete {
        color: $color-accent-green;
        .ca-page-empup-steps-step-check g {
          stroke: white;
          fill: $color-accent-green;
          circle {
            stroke: $color-accent-green;
          }
        }
      }
      &.active {
        color: $color-gray-midnight;
        border-color: $color-gray-midnight;
      }
    }
  }

  &-next-con {
    @include flexbox(row, center, center);
    margin: ($spacer * 4) 0;
    gap: $spacer;
  }

  &-csv {
    & > .ca-box {
      width: 600px;
      margin: ($spacer * 2) auto;
      // margin-top: ($spacer * 4) !important;
    }
    &-loading {
      width: 24px;
      color: $color-gray-slate;
      display: block;
    }
    &-invalid {
      color: $color-danger;
    }
    &-input {
      // margin-top: $spacer * 3;
    }
  }

  &-cols {
    &-header-cb {
      @include flexbox(row-reverse, center, flex-start);
      gap: $spacer * 0.5;
    }
    &-table-con {
      // border: 1px solid red;
      margin: ($spacer * 1.5) ($spacer * -3);
      position: relative;
      &:before, &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: $spacer * 3;
        z-index: 1;
      }
      &:before {
        left: 0;
        background: linear-gradient(to right, white, transparent);
      }
      &:after {
        right: 0;
        background: linear-gradient(to left, white, transparent);
      }
      .ca-box-table {
        margin: 0 ($spacer * 3);
      }
      thead tr th {
        position: sticky;
        top: 0;
        background-color: $color-gray-ice;
        border-top: 1px solid $color-gray-cloud;
      }
    }
    &-table-scroll {
      // border: 1px solid lime;
      overflow: auto;
      max-height: 400px;
    }
  }

  &-attr-errors {
    text-align: center;
    color: $color-danger;
    margin-top: $spacer * 4;
    p {
      margin: $spacer;
    }
  }

  &-vals {
    &-cols {
      // border: 1px solid red;
      margin: ($spacer * -2) ($spacer * -4);
      position: relative;
      &:before, &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: $spacer * 4;
        z-index: 1;
      }
      &:before {
        left: 0;
        background: linear-gradient(to right, white, transparent);
      }
      &:after {
        right: 0;
        background: linear-gradient(to left, white, transparent);
      }
      &-scroll {
        // border: 1px solid lime;
        @include flexbox(row, stretch, flex-start);
        padding: ($spacer * 2) ($spacer * 4);
        gap: $spacer * 4;
        overflow: auto;
        .ca-box {
          margin: 0;
          &:first-child {
            margin-left: auto;
          }
          &:last-child {
            margin-right: auto;
          }
        }
      }
      .standard-select-option.danger {
        color: $color-danger;
      }
    }
    &-col {
      position: relative;
      &-check {
        width: 16px;
        position: absolute;
        top: $spacer * 1.5;
        right: $spacer * 1.5;
        g {
          fill: $color-danger;
          stroke: $color-danger;
        }
        &.complete g {
          fill: $color-accent-green;
          stroke: white;
          circle {
            stroke: $color-accent-green;
          }
        }
      }
      &-count {
        text-transform: uppercase;
        margin: 0;
        text-align: center;
        font-size: 14px;
        font-weight: $cerebri-regular;
      }
      &-title {
        font-weight: $cerebri-heavy;
        font-size: 18px;
        margin: 0;
        margin-top: $spacer * 3;
      }
      &-table {
        border-collapse: separate;
        &-con {
          margin-top: $spacer * 1.5;
          max-height: 600px;
          // border: 1px solid red;
          padding-bottom: 300px;
          overflow: auto;
        }
        .cell-csv {
          &-label {
            white-space: nowrap;
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-count {
            white-space: nowrap;
            font-weight: $cerebri-regular;
            text-transform: none;
            font-size: 12px;
          }
        }
        &-header th {
          color: $color-text-default !important;
          font-weight: $cerebri-bold !important;
          text-transform: none !important;
          white-space: nowrap;
          // font-size: 16px;
          border-bottom: 1px solid $color-gray-cloud;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
        }
      }
    }
  }

  &-upload {
    & > .ca-box {
      width: 600px;
      margin: ($spacer * 2) auto;
    }
    &-title {
      font-weight: $cerebri-heavy;
      margin: 0;
      font-size: 22px;
      margin-bottom: $spacer * 2;
    }
    &-field {
      position: relative;
      margin-left: $spacer * 2;
      &-check {
        width: 18px;
        color: $color-accent-green;
        position: absolute;
        top: 0;
        left: 0;
        g {
          stroke: white;
          fill: $color-accent-green;
        }
      }
      &-name, &-subtext {
        margin-left: $spacer + 18px;
      }
      &-name {
        font-weight: $cerebri-bold;
      }
      &-subtext {
        // font-style: italic;
        color: $color-text-medium-1-5;
      }
      & + & {
        margin-top: $spacer * 2;
      }
    }
  }

  &-uploading {
    text-align: center;
    margin-top: $spacer * 4;
    &-text {
      font-weight: $cerebri-heavy;
      text-transform: uppercase;
    }
    &-bar {
      width: 200px;
      margin: $spacer auto;
      background-color: $color-gray-cloud;
      border-radius: 100px;
      &-progress {
        height: 8px;
        background-color: $color-accent-blue;
        border-radius: 100px;
        transition: all 500ms;
      }
    }
    &-notice {
      color: $color-accent-blue;
    }
  }

  &-success {
    text-align: center;
    & > .ca-box {
      width: 600px;
      margin: ($spacer * 2) auto;
    }
    &-icon {
      width: 100px;
      color: $color-accent-purple;
      margin-top: $spacer * 4;
      margin-right: -15px;
      g {
        stroke-width: 0.7;
      }
    }
    &-title {
      font-weight: $cerebri-heavy;
      font-size: 22px;
      margin: 0;
      margin-top: $spacer * 2;
    }
    &-actions {
      margin-top: $spacer * 4;
      @include flexbox(row, center, center);
    }
  }
}






/*
 *  Modal New Employee
 */

.modal-emps-new {

  &-cb {
    @include flexbox(row, center, flex-start);
    margin-right: $spacer;
    .checkbox {
      margin-right: $spacer * 0.5;
    }
  }

}






/*
 *  Modal Cattr Cols
 */

.ca-modal-cattr-cols {
  &-cattrs {
    @include flexbox(column, flex-start, flex-start);
    gap: $spacer;
    margin: $spacer * 2;
    &-cb-row {
      @include flexbox(row, center, flex-start);
      gap: $spacer;
    }
  }
}
