.brick {
  display: block;
  container-type: inline-size;
  text-decoration: none !important;
  color: $color-text-default;
  border-radius: 3px;
  height: 180px;
  box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
  transition: all 200ms;
  position: relative;
  overflow: hidden;

  &-flex {
    @include flexbox(row, flex-start, flex-start);
    height: 100%;
  }

  &-company-logo {
    position: absolute;
    bottom: 12px;
    left: 12px;
    background-color: white;
    height: 28px;
    border-radius: 100px;
    &-img {
      max-height: 20px;
      max-width: 85px;
      display: block;
      margin: 4px 12px;
    }
  }

  &-img {
    display: block;
    width: 208px;
    max-width: 33%;
    height: 100%;
    object-fit: cover;
  }

  &-content {
    font-size: 13px;
    padding: $spacer * 2;
    height: 100%;
    flex: 1;
    overflow: hidden;
    @include flexbox(column, stretch, flex-start);

    &-title {
      font-size: 16px;
      font-weight: $cerebri-heavy;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 0;
    }

    &-body {
      flex: 1;
      line-height: 17px;
      &-detail {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        margin-top: $spacer * 0.5;
        & + & {
          margin-top: 0;
        }
        &-status {
          font-weight: $cerebri-bold;
          text-transform: uppercase;
          &.status-active { color: $color-accent-green; }
          &.status-upcoming { color: $color-accent-blue; }
          &.status-ended { color: $color-danger; }
        }        
      }
      &-desc {
        color: $color-text-medium-1-5;
        margin: 0;
        margin-top: $spacer * 0.5;
        max-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &-footer {
      @include flexbox(row, center, flex-start);
      white-space: nowrap;
      overflow: hidden;
      &-badge {
        color: white;
        text-transform: uppercase;
        background: $gradient-black;
        border-radius: 100px;
        padding: 2px 9px;
        flex-shrink: 0;
      }
      &-goal {
        flex: 1;
        overflow: hidden;
        flex-shrink: 1;
        font-weight: $cerebri-bold;
      }
      &-badge + &-goal {
        margin-left: 3px;
      }
      &-cta {
        flex-shrink: 0;
        text-align: right;
        @include flexbox(row, center, flex-end);
        text-transform: uppercase;
        margin-left: 3px;
        svg.icon {
          width: 10px;
          margin-left: 3px;
          display: block;
        }
      }
    }
  }

  &.click-yes:hover {
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.25);
    transform: translateY(-2px);
    .brick-content-footer-cta {
      font-weight: $cerebri-bold;
      transform: translateX(2px);
      svg.icon {
        stroke-width: 2px;
      }
    }
  }

  &.color-green {
    .brick-content-footer-badge { background: $gradient-green; }
    .brick-content-footer-cta { color: $color-accent-green; }
  }
  &.color-purple {
    .brick-content-footer-badge { background: $gradient-purple; }
    .brick-content-footer-cta { color: $color-accent-purple; }
  }
  &.color-orange {
    .brick-content-footer-badge { background: $gradient-orange; }
    .brick-content-footer-cta { color: $color-accent-orange; }
  }
  &.color-groups {
    .brick-content-footer-badge { background: $gradient-groups; }
    .brick-content-footer-cta { color: $color-groups-a; }
  }

  &.ghost {
    opacity: 0.3;
  }

  @container (max-width: 360px) {
    &-content {
      padding-left: $spacer;
      padding-right: $spacer;
    }
    &-img {
      max-width: 25%;
    }
  }

}
