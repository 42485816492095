.ca-box {
  margin: ($spacer * 2) 0;
  box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
  border-radius: 5px;

  &-loading {
    width: 24px;
    color: $color-gray-fog;
  }

  &-header {
    display: flex;
    flex-direction: row;
    padding: ($spacer * 3) ($spacer * 3) 0;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &-img {
        width: 29px;
        display: inline-block;
        margin-right: $spacer;
        border-radius: 3px;
        box-shadow: 0 0 3px rgba(0,0,0,0.1);
        & + span {
          text-transform: none;
        }
      }

      svg.icon {
        margin-right: $spacer;
      }
    }

    &-controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: $spacer * -1;

      &-sep {
        width: 1px;
        background-color: $color-gray-cloud;
        align-self: stretch;
      }
      > p {
        margin-top: 0;
      }
      > * {
        margin-left: $spacer;
        margin-bottom: $spacer !important;
      }
      .auto-complete-input {
        height: 45px;
      }
      &-cb-con {
        &:not(:last-child) {
          padding-right: $spacer;
        }
        @include flexbox(row, center, flex-start);
        .checkbox + label, label + .checkbox {
          margin-left: calc($spacer / 2);
        }
      }
    }

    &.gray {
      background-color: $color-gray-ice;
      padding: ($spacer * 2) ($spacer * 3);
    }
  }

  &-subheader {
    padding: ($spacer * 1.5) ($spacer * 3) 0;

    & > p:first-child {
      margin-top: 0;
    }
  }

  &-tabs {
    border-bottom: 1px solid $color-gray-cloud;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-tabs {
      display: flex;
      flex-direction: row;
      margin-top: $spacer;
      margin-left: $spacer * 2;
      overflow: hidden;
      width: 100%;
    }

    &-tab {
      color: $color-text-faint;
      text-decoration: none !important;
      border: 2px solid transparent;
      border-width: 2px 0;
      padding: $spacer;
      transition: all 200ms;
      white-space: nowrap;
      text-align: center;

      &.active {
        color: $color-text-default;
        font-weight: $cerebri-bold;
        border-bottom-color: $color-text-default;
      }
      &:hover {
        color: $color-text-default;
      }

      &.separate {
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          top: 2px;
          left: $spacer * -2;
          background-color: $color-gray-cloud;
        }
        // border-left: 1px solid $color-gray-cloud;
        margin-left: $spacer * 4;
      }

      &-attn {
        background-color: $color-accent-pink;
        color: white;
        font-weight: $cerebri-bold;
        font-size: 12px;
        padding: 0px 4px;
        border-radius: 5px;
        margin-left: calc($spacer / 2);
      }
    }
  }

  &-body {
    padding: $spacer * 3;
    // overflow: auto;
  }

  &-footer {
    padding: 0 ($spacer * 3) ($spacer * 3);
  }

  &.vertical-tight {
    .ca-box-header {
      padding-top: $spacer * 2;
    }
    .ca-box-body {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }
    .ca-box-footer {
      padding-bottom: $spacer * 2;
    }
  }

  &-table {
    width: 100%;
    th {
      color: $color-text-faint;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: $cerebri-regular;
    }
    th, td {
      text-align: left;
      padding: $spacer;
      border-bottom: 1px solid $color-gray-cloud;
      &.min-140 { min-width: 140px; }
      &.min-200 { min-width: 200px; }
      &.right {
        text-align: right;
      }
      &.nowrap {
        white-space: nowrap;
      }
      &.nooverflow {
        overflow: hidden;
      }
      &.hidden {
        padding: 0;
        width: 0;
        min-width: 0 !important;
      }
      &.strong {
        font-weight: $cerebri-bold;
      }
      &.icon {
        .popper-target {
          width: 20px;
          height: 20px;
        }
        svg.icon {
          width: 20px;
          display: block;
        }
      }
      & > .menu-cell {
        @include flexbox(row, center, space-between);
        gap: $spacer;
      }
    }

    &:not(.no-hover-highlight) {
      tr:hover td {
        background-color: #FCFCFC;
      }
    }

    &.clickable {
      tbody tr:hover {
        background-color: $color-highlight-blue;
        cursor: pointer;
      }
    }
  }
  &-table + .pagination,
  .scrolltable + .pagination {
    margin-top: $spacer * 2;
  }

}
