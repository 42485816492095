
svg.icon.caret {
  transition: all 150ms;
  &.up { transform: rotate(180deg); }
  &.right { transform: rotate(-90deg); }
  &.left { transform: rotate(90deg); }
}

svg.icon.goodorbad {
  &.good { color: $color-accent-green; }
  &.bad  { color: $color-danger; }
}

svg.slack.color {
  path:nth-child(1) { fill: #00C8F5; stroke: #00C8F5; stroke-width: 0.5px; }
  path:nth-child(2) { fill: #00BA78; stroke: #00BA78; stroke-width: 0.5px; }
  path:nth-child(3) { fill: #F6B000; stroke: #F6B000; stroke-width: 0.5px; }
  path:nth-child(4) { fill: #F40058; stroke: #F40058; stroke-width: 0.5px; }
}

.validation-message {
  text-transform: uppercase;
  font-size: 11px;
  color: $color-danger;
}

/*
 *  Links
 */

a.pink-hover {
  color: inherit;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: none;
    color: $color-accent-pink;
  }
}
a.blue-pink-hover {
  color: $color-accent-blue;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: none;
    color: $color-accent-pink;
  }
}

/*
 *  Dropdowns
 */

.dd {
  display: inline-block;
  position: relative;
  min-width: 200px;

  &-button {
    display: inline-block;
    background-color: transparent;
    border: 1px solid $color-gray-cloud;
    padding: 0 ($spacer * 2) 0 $spacer;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    outline: none;
    cursor: pointer;
    height: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-menu {
    display: none;
    z-index: 1000;
    position: absolute;
    border: 1px solid $color-gray-cloud;
    border-radius: 1px;
    top: 45px;
    left: 0; right: 0;
    background-color: white;
    margin-top: -1px;

    &-option {
      padding: 12px;
      padding-left: 8px;
      border-left: 4px solid transparent;
      cursor: pointer;

      &:hover, &.selected {
        background-color: $color-gray-ice;
        border-color: $color-gray-midnight;
      }
    }

    &-inline-input {
      display: inline-block;
      width: 50px;
      background: none;
      border: none;
      border-bottom: 1px solid $color-text-default;
      border-top: 1px solid transparent;
      padding: calc($spacer / 4) 0;
      outline: none;
      margin: -4px 0;
      font-size: inherit;
      text-align: center;

      &::placeholder {
        color: $color-text-faint;
      }
    }
  }

  &.invalid {
    .dd-menu {
      margin-top: 0;
    }
    .dd-button {
      border-color: $color-danger;
    }
  }

  &.disabled {
    opacity: 0.5;
    .dd-button {
      cursor: not-allowed;
      color: inherit;
    }
  }

  &-invalid-value {
    color: red;
  }

  &.align-right {
    .dd-menu {
      left: unset;
    }
  }

  &-caret {
    display: block;
    position: absolute;
    right: $spacer;
    top: 19px;
    color: $color-gray-slate;
    width: 10px;
    height: 10px;
    transition: all 100ms;
  }

  &-clear-btn {
    padding: 0;
    position: absolute;
    inset: 2px 2px 2px auto;
    transition: all 100ms;
    width: 28px;
    background-color: white;
    cursor: pointer;
    @include flexbox(row, center, center);
    color: $color-gray-slate;
    &:hover {
      color: $color-gray-midnight;
      svg.icon {
        background-color: $color-gray-cloud;
        border-color: $color-gray-cloud;
      }
    }
    svg.icon {
      border-radius: 100px;
      width: 18px;
      border: 3px solid white;
      g {
        stroke-width: 2;
      }
    }
  }

  &.open {
    .dd-button {
      box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    }
    .dd-menu {
      display: block;
      box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    }
    .dd-caret {
      transform: rotate(180deg);
    }
  }

  &.select {
    .select-menu {
      max-height: 300px;
      overflow: auto;

      &-search {
        margin: 6px;
        width: unset;
        min-width: 0;
      }

      &-option {
        hr {
          margin: 0;
        }
        &:not(.no-select):hover {
          background-color: $color-highlight-blue;
          cursor: pointer;
        }
      }
    }
  }
}

/*
 *  Standard Select
 */

.standard-select {
  .dd-button {
    background-color: white;
  }
  &-option {
    &:not(.selected) {
      padding: $spacer;
      cursor: pointer;
      border-left: 2px solid transparent;
      color: $color-text-default;
      text-transform: none;
      &:hover {
        background-color: $color-highlight-blue;
        color: $color-accent-blue;
        font-weight: $cerebri-bold;
        border-color: $color-accent-blue;
      }
    }
    &.selected:not(.no-highlight) {
      font-weight: $cerebri-bold;
    }
    &.selected.null {
      font-weight: $cerebri-regular;
      color: $color-text-medium-1-5;
    }
  }
}

/*
 *  Country Select
 */

.country-select {
  &-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-emoji {
      font-size: 22px;
      width: 29px;
    }

    &.reset {
      @extend .faint;
      padding-bottom: $spacer;
    }

    &.selected {
      font-weight: $cerebri-bold;
    }

    &:not(.selected) {
      padding: calc($spacer / 2) $spacer;
      cursor: pointer;
      border-left: 2px solid transparent;
      &:hover {
        background-color: $color-highlight-blue;
        color: $color-accent-blue;
        font-weight: $cerebri-bold;
        border-color: $color-accent-blue;
      }
    }
  }
}

/*
 *  Timezone Input
 */

.timezone-input {
  .dd-button {
    background-color: white;
  }
  &-option {
    padding: $spacer;
    &:hover:not(.selected) {
      background-color: $color-highlight-blue;
      cursor: pointer;
    }
    &.selected {
      font-weight: $cerebri-bold;
      padding-left: 0;
    }
    &.placeholder {
      color: $color-gray-slate;
      font-weight: $cerebri-regular;
    }
  }
}

/*
 *  Calendar
 */

.calendar {
  overflow: hidden;

  &-month {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer;

    &-label {
      font-weight: $cerebri-bold;
      font-size: 16px;
    }

    &-toggles {
      button {
        background-color: $color-gray-ice;
        border: none;
        border-radius: 100px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        outline: none;
        cursor: pointer;
        color: $color-gray-slate;
        margin-left: calc($spacer / 2);
        &:first-child svg.icon {
          margin-left: -1px;
        }
        &:nth-child(2) svg.icon {
          margin-right: -1px;
        }
        &:hover {
          background-color: $color-gray-cloud;
          color: $color-gray-midnight;
        }
      }
      svg.icon {
        margin-top: 0.5px;
        width: 12px;
      }
    }
  }

  &-day {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 14.2857%; // 1/7
    height: 27px;
    line-height: 27px;
    z-index: 1;
    color: $color-text-medium-1;
    margin: 3px 0;
    cursor: pointer;
    transition: all 200ms;

    &:before {
      position: absolute;
      display: block;
      content: '';
      height: 27px;
      width: 27px;
      border-radius: 100px;
      background-color: transparent;
      z-index: -1;
      top: 0;
      left: 50%;
      margin-left: -13.5px;
      transition: background-color 200ms;
    }

    &.header {
      color: $color-text-medium-2 !important;
      cursor: initial;
      &:before, &:after {
        display: none;
      }
    }
    &.trailing {
      opacity: 0; // don't show trailing days
      color: $color-text-fainter;
    }
    &.today {
      font-weight: $cerebri-bold;
    }
    &.range {
      &:after {
        position: absolute;
        display: block;
        content: '';
        height: 27px;
        width: 100%;
        background-color: rgba($color-accent-pink, 0.08);
        z-index: -2;
        top: 0;
        left: 0;
      } 
      &.start:after {
        width: 50%;
        left: 50%;
      }
      &.end:after {
        width: 50%;
        right: 50%;
        left: initial;
      }
    }
    &.start, &.end, &:hover {
      color: white;
      &:before {
        background-color: $color-accent-pink;
      }
    }
  }
}

/*
 *  Date Range Picker
 */

.date-range-picker {
  width: 220px;

  .calendar {
    width: 284px;
    margin: ($spacer * 2) ($spacer * 2) 0;
  }

  .dd-button {
    .empty {
      color: $color-text-faint;
    }
  }

  .dd-menu {
    right: 0;
    left: auto;
  }

  &.left-align {
    .dd-menu {
      left: 0;
      right: auto;
    }
  }

  &-menu {
    background-color: white;
  }

  .dpm {
    display: flex;
    flex-direction: row;

    &-text-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $color-gray-cloud;
      padding: 0 ($spacer * 2) $spacer;

      .standard-input {
        flex: 1;
        margin-bottom: 0;
        margin-left: $spacer;
      }
    }

    &-start, &-end {
      margin: ($spacer * 2) 0;
    }

    &-start {
      border-right: 1px solid $color-gray-cloud;
    }

    &-buttons {
      width: 180px;
      border-left: 1px solid $color-gray-cloud;
      padding: $spacer;
      display: flex;
      flex-direction: column;
    }

    &-quick-options {
      flex: 1;
    }

    &-quick-option {
      border: none;
      outline: 0;
      background: 0 0;
      padding: $spacer;
      font-size: 16px;
      margin: 0;
      display: block;
      cursor: pointer;
      &:hover, &.active {
        font-weight: $cerebri-bold;
      }
    }

    &-apply.btn {
      margin: $spacer !important;
    }
  }

  @include media("<medium") {
    .dd-menu {
      width: 100%;
    }
    .dpm {
      &-start, &-end {
        display: none;
      }
    }
  }
}

/*
 *  Pagination
 */

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  color: $color-gray-slate;

  &-back, &-next {
    background: none;
    border: 1px solid $color-gray-slate;
    width: 18px;
    height: 18px;
    outline: none;
    margin: 0 $spacer;
    border-radius: 100px;
    padding: 0;
    line-height: 18px;
    padding-top: 1px;
    cursor: pointer;
    color: $color-gray-slate;

    &:hover {
      color: $color-gray-midnight;
      border-color: $color-gray-midnight;
    }

    svg.icon {
      width: 12px;
    }
  }
  &-back {
    padding-right: 1px;
  }
  &-next {
    padding-left: 1px;
  }
}

/*
 *  Time Picker
 */

.dd.time-picker {
  min-width: 120px;

  &.empty {
    .dd-button {
      color: $color-text-faint;
    }
  }
  &.filled {
    .dd-button {
      font-weight: $cerebri-bold;
    }
  }

  .dd-menu {
    width: 220px;
  }

  .time-picker-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }

  .time-picker-colon {
    padding: 0 2px;
  }
  .time-picker-control {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .standard-input {
      margin: 0;
      input {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-inc, &-dec {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: center;
      color: $color-gray-cloud;
      cursor: pointer;
      &:hover {
        color: $color-gray-midnight;
      }
      svg.icon {
        width: 18px;
        display: block;
        margin: $spacer auto;
      }
    }
    &-inc svg.icon {
      margin-bottom: calc($spacer / 2);
    }
    &-dec svg.icon {
      margin-top: calc($spacer / 2);
    }

    &.minute {
      margin-right: $spacer;
    }
  }
}

/*
 *  Date Picker
 */

.dd.date-picker {
  min-width: 230px;
  &.empty {
    .dd-button {
      color: $color-text-faint;
    }
  }
  &.filled {
    .dd-button {
      font-weight: $cerebri-bold;
    }
  }
  .calendar {
    margin: $spacer;
  }
}

/*
 *  Progress Bar
 */

.progress-bar {
  margin: $spacer 0 0 0;

  &-labels {
    @include flexbox(row, baseline, space-between);
    margin-bottom: calc($spacer / 4);
    // color: $color-accent-black;
    &-title {
      font-weight: $cerebri-bold;
    }
    &-target {
      color: $color-text-faint;
    }
  }
  &-bar {
    height: 15px;
    background-color: $color-gray-cloud;
    border-radius: 100px;
    overflow: hidden;
    &-complete {
      height: 100%;
      background: $gradient-black;
      border-radius: 100px;
      transition: width 600ms;
    }
  }
  &.green {
    .progress-bar-labels { color: $color-accent-green; }
    .progress-bar-bar-complete { background: $gradient-green; }
  }
  &.purple {
    .progress-bar-labels { color: $color-accent-purple; }
    .progress-bar-bar-complete { background: $gradient-purple; }
  }
  &.title-large {
    .progress-bar-labels-title {
      font-size: 18px;
      font-weight: $cerebri-heavy;
    }
  }
  &.title-xl {
    .progress-bar-labels-title {
      font-size: 30px;
      font-weight: $cerebri-heavy;
    }
  }
  &.bar-thin {
    .progress-bar-bar {
      height: 7px;
    }
  }
}

/*
 *  Recent Donors
 */

 .recent-donors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: $spacer * 2;
  padding: 0 ($spacer * 2);

  &-images {
    display: flex;
    flex-direction: row;
    margin-right: $spacer;
    padding-left: 5px;

    img {
      display: block;
      width: 29px;
      height: 29px;
      border-radius: 100%;
      background-color: white;
      border: 1px solid white;
      box-shadow: 0 2px 4px rgba(0,0,0,0.12);
      object-fit: cover;
      margin-left: -5px;
    }
  }
}



/*
 *  Ellipsis Menu
 */

.ellipsis-menu {
  display: inline-block;
  &-toggle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    @include flexbox(row, center, center);
    padding: 0;
    color: $color-gray-slate;
    outline: none;
    &:focus {
      box-shadow: 0px 0px 1px $color-accent-blue;
    }
    svg.icon {
      display: block;
      width: 20px;
    }
  }
  &:not(.disabled) {
    &:hover, &:focus {
      .ellipsis-menu-toggle {
        background-color: $color-highlight-blue;
        color: $color-accent-blue;
        cursor: pointer;
      }
    }
  }
  & > .btn {
    vertical-align: bottom;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &-dropdown {
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $spacer;
      color: inherit;
      white-space: nowrap;
      border-left: 2px solid transparent;
      svg.icon {
        width: 20px;
        margin-right: calc($spacer / 2);
      }
      &:hover {
        background-color: $color-highlight-blue;
        text-decoration: none;
        color: $color-accent-blue;
        border-left-color: $color-accent-blue;
      }
    }
  }
}

/*
 *  Card Picker
 */

.card-picker {
  width: 300px;

  &-selected {
    width: 100%;
    display: block;
    margin-bottom: calc($spacer / 2);
    border: 1px solid $color-gray-cloud;
    position: relative;
    img {
      width: 100%;
      display: block;
      min-height: 210px;
    }
    &-hover {
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0,0,0,0.8);
      color: white;
      // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      opacity: 0;
      transition: opacity 200ms;
      cursor: pointer;
    }
    &:hover {
      .card-picker-selected-hover {
        opacity: 1;
      }
    }
  }

  &-modal {
    &-head {
      @include flexbox(row, center, flex-start);
      margin: $spacer * 4;
      margin-bottom: 0;
      &-title {
        font-weight: $cerebri-heavy;
        font-size: 30px;
        margin: 0;
        margin-right: $spacer * 4;
      }
    }
    &-cards {
      padding: ($spacer * 4) ($spacer * 2) ($spacer * 2) ($spacer * 4);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1200px;
      &-card {
        width: 200px;
        height: 142px;
        border: 3px solid transparent;
        margin-right: $spacer * 1.75;
        margin-bottom: $spacer * 1.75;
        transition: border-color 200ms;
        cursor: pointer;
        &.selected, &:hover {
          border-color: $color-accent-blue;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

/*
 *  Connector Line
 */

.connector-line {
  height: 17px;
  position: relative;

  svg.icon {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -8.5px;
    z-index: 1;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $color-gray-cloud;
    // z-index: -2;
  }
  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -12.5px;
    height: 100%;
    width: 25px;
    background-color: white;
    // z-index: -1;
  }
  &.error {
    &:after {display: block;}
    &:before {background-color: $color-danger;}
    svg.icon {color: $color-danger;}
  }
  &.success {
    &:after {display: block;}
    &:before {background-color: $color-accent-green;}
    svg.icon {color: $color-accent-green;}
  }
}

/*
 *  Tip
 */

.tip {
  position: relative;
  width: 15px;
  height: 15px;
  &-i {
    width: 100%;
    height: 100%;
    color: $color-gray-slate;
  }
  &-message {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    font-size: 14px;
    font-weight: $cerebri-regular;
    text-transform: none;
    padding: $spacer * 1.5;
    z-index: 1;
    width: max-content;
    max-width: 200px;
    bottom: 15px + ($spacer);
    left: 50%;
    transform: translateX(-50%);
    &:after {
      position: absolute;
      content: '';
      display: block;
      border: 8px solid transparent;
      border-bottom: none;
      border-top-color: white;
      top: 100%;
      left: 50%;
      margin-left: -8px;
    }
    &:before {
      position: absolute;
      content: '';
      display: block;
      border: 10px solid transparent;
      border-bottom: none;
      border-top-color: rgba(0,0,0,0.05);
      top: 100%;
      left: 50%;
      margin-left: -10px;
    }
  }
  &-con:hover, &:hover {
    .tip-i {
      color: $color-accent-blue;
    }
    .tip-message {
      display: block;
    }
  }
}



/*
 *  Notices
 */

.notice {
  border: 1px solid $color-accent-blue;
  background-color: $color-highlight-blue;
  padding: $spacer;
  border-radius: 2px;
  position: relative;

  &.icon {
    padding-left: ($spacer * 2) + 20px;
  }

  svg.icon {
    width: 20px;
    color: $color-accent-blue;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: $spacer;
  }

  &.yellow {
    border-color: $color-accent-yellow;
    background-color: lighten($color-accent-yellow, 45%);
  }
}



/*
 *  Currency Picker
 */

.currency-picker {
  // border: 1px solid lime;
  position: relative;
  width: 90px;
  height: 26px;
  &-btn {
    background-color: white;
    border: 1px solid $color-gray-cloud;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: pointer;
    font-weight: $cerebri-bold;
    transition: all 200ms;
    @include flexbox(row, center, space-between);
    &:focus {
      border: 1px solid $color-gray-midnight;
      .currency-picker-btn-caret {
        color: $color-gray-midnight;
        transform: rotate(180deg);
      }
    }
    &:hover {
      background-color: $color-highlight-blue;
    }
    &-caret {
      flex-shrink: 0;
      width: 10px;
      margin-right: calc($spacer / 2);
      color: $color-gray-slate;
    }
    &-saving {
      font-size: 12px;
      padding-left: calc($spacer / 2);
    }
  }
  &-menu {
    border: 1px solid $color-gray-midnight;
    border-radius: 3px;
    width: 100%;
    position: absolute;
    top: 27px;
    left: 0;
    display: none;
    background-color: white;
    box-shadow: $shadow;
    height: 180px;
    overflow: auto;
    z-index: 1;
    &-btn {
      cursor: pointer;
      width: 100%;
      transition: all 200ms;
      height: 40px;
      padding: 0;
      margin: 0;
      &:hover {
        background-color: $color-highlight-blue;
        font-weight: $cerebri-bold;
      }
    }
  }
  &-currency {
    height: 100%;
    @include flexbox(row, center, flex-start);
    padding: 0 calc($spacer / 2);
    &-flag {
      font-size: 22px;
      line-height: 22px;
      margin-right: calc($spacer / 4);
    }
    &-code {
      line-height: 22px;
    }
  }
  &-btn:focus + &-menu, &-btn + &-menu:active {
    display: block;
  }
}

.ntee-code {
  @include flexbox(row, center, flex-start);
  svg.icon {
    width: 24px;
    flex-shrink: 0;
    margin-right: calc($spacer / 2);
  }
}



/*
 *  Phone Input
 */

.phone-input {
  // border: 1px solid lime;

  &-con {
    @include flexbox(row, flex-start, flex-start);
    position: relative;
  }

  &-vmsg {
    color: $color-danger;
    font-size: 11px;
    text-transform: uppercase;
  }

  &.vmsg {
    .phone-input-number input, .phone-input-country .dd-button {
      border-color: $color-danger;
    }
  }

  .standard-input, .standard-select {
    margin-bottom: 0;
  }

  &-country {
    width: 90px;
    min-width: unset;
    margin-right: -1px;
    .dd-button {
    }
    .dd-menu {
      width: 240px;
    }
  }

  &-number {
    flex: 1;
  }

  &-vld-icon {
    position: absolute;
    top: 12.5px;
    right: $spacer;
    width: 20px;
    // display: none;
  }
  &.validate-pending &-vld-icon { display: block; color: $color-gray-slate; }
  &.validate-failed  &-vld-icon  { display: block; color: $color-danger; }
  &.validate-success &-vld-icon { display: block; color: $color-accent-green; }
}



/*
 *  Fancy Image
 */

.fancy-img {
  width: 100%;
  // aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;

  &-bg {
    position: absolute;
    // inset: -20px;
    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // filter: blur(20px);
      opacity: 0.7;
    }
  }
  &-main {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}



/*
 *  Multi Image Input
 */

.multi-img-input {
  border: 1px solid $color-gray-ice;
  padding: 24px;
  background-color: $color-gray-ice;
  height: 120px;
  @include flexbox(column, center, center);
  transition: all 200ms;
  color: $color-text-medium-1-5;
  border-radius: 2px;
  position: relative;
  &-input {
    display: none;
  }
  &-prompt {
    @include flexbox(column, center, center);
    &-icon {
      width: 24px;
      margin-bottom: $spacer;
    }
    &-span1 {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
    }
  }
  &-hide {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 100px;
    @include flexbox(row, center, center);
    transition: all 200ms;
    cursor: pointer;
    color: $color-gray-slate;
    opacity: 0;
    svg.icon {
      width: 12px;
      flex-shrink: 0;
      g {
        stroke-width: 2px;
      }
    }
    &:hover {
      background-color: white;
      color: $color-gray-midnight;
    }
  }
  &:hover:not(.uploading) {
    background-color: $color-gray-cloud;
    color: $color-text-default;
    cursor: pointer;
    .multi-img-input-hide {
      opacity: 1;
    }
  }
  &.uploading {
    // color: $color-text-default;
    font-weight: $cerebri-bold;
  }
  &.drag-over:not(.uploading) {
    border-color: $color-accent-blue;
    background-color: $color-gray-cloud;
    color: $color-text-default;
  }
  &.hide {
    display: none;
  }
}



/*
 *  Emoji Picker
 */

.emopick {
  // border: 1px solid $color-gray-cloud;
  width: 366px;
  max-width: 100%;
  border-radius: 2px;
  overflow: hidden;

  &-loading {
    color: $color-gray-fog;
    width: 24px;
    display: block;
    margin: ($spacer * 2) auto;
  }

  &-head {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      height: $spacer;
      width: 100%;
      top: 100%;
      left: 0;
      background: linear-gradient(to bottom, white, transparent);
      z-index: 1;
    }
  }

  &-tabs {
    @include flexbox(row, center, space-around);
    overflow: auto;
    background-color: $color-gray-ice;
    padding: ($spacer * 0.5) $spacer;
    border-bottom: 1px solid $color-gray-cloud;
    &-tab {
      flex-shrink: 0;
      cursor: pointer;
      border: none;
      padding: 6px 0;
      &.active {
        // border-bottom-color: $color-gray-fog;
        .emopick-tabs-tab-icon {
          color: $color-gray-midnight;
        }
      }
      &-icon {
        width: 24px;
        height: 24px;
        color: $color-gray-slate;
        display: block;
      }
    }
  }

  &-body {
    height: 300px;
    max-height: 90vh;
    overflow: auto;
    padding: $spacer;
    padding-top: 0;
  }

  &-search {
    margin-top: $spacer;
    margin-bottom: 0 !important;
  }

  &-majors {
    &-name {
      margin-top: 9px;
      margin-bottom: 3px;
      padding: 3px 0;
      text-transform: uppercase;
      color: $color-text-medium-1-5;
      font-weight: $cerebri-bold;
      position: sticky;
      top: 0;
      background-color: rgba(255,255,255,0.9);
      z-index: 2;
    }
  }

  &-results {
    margin-top: $spacer;
  }

  &-btn {
    width: 38px;
    height: 38px;
    @include flexbox(row, center, center);
    transition: all 200ms;
    cursor: pointer;
    overflow: hidden;
    &-char {
      font-size: 26px;
    }
    border-radius: 3px;
    &:hover {
      // background-color: $color-highlight-blue;
      background-color: rgba($color-accent-blue, 0.3);
      transform: translateY(-2px);
    }
  }

  &-list {
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
  }

  &-foot {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      height: $spacer;
      width: 100%;
      bottom: 100%;
      left: 0;
      // background: linear-gradient(to top, rgba(0,0,0,0.08), transparent);
      background: linear-gradient(to top, white, transparent);
      z-index: 1;
    }
  }

  &-quick {
    background-color: $color-gray-ice;
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    padding: ($spacer * 0.5) $spacer;
    height: 44px;
    border-top: 1px solid $color-gray-cloud;
    @-webkit-keyframes rotate-in-r {
      0%  {transform: rotate(0deg) ;}
      100% {transform: rotate(360deg) ;}
    }
    @-webkit-keyframes rotate-in-l {
      0%  {transform: rotate(0deg) ;}
      100% {transform: rotate(-360deg) ;}
    }
    .rotate-in-r {
      animation: rotate-in-r 400ms;
    }
    .rotate-in-l {
      animation: rotate-in-l 400ms;
    }
    &-refresh {
      flex: 1;
      color: $color-gray-slate;
      @include flexbox(row, center, flex-end);
      transition: all 200ms;
      cursor: pointer;
      &:hover {
        color: $color-gray-midnight;
      }
      svg.icon {
        width: 18px;
        display: block;
      }
    }
  }

  &-target {
    background-color: $color-gray-ice;
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    padding: ($spacer * 0.5) $spacer;
    height: 44px;
    border-top: 1px solid $color-gray-cloud;
    &-char {
      font-size: 30px;
    }
    &-name {
      font-weight: $cerebri-bold;
      color: $color-text-medium-1-5;
    }
  }
}



/*
 *  SCROLL TABLE
 */

.scrolltable {
  position: relative;
  &-2 {
    overflow: auto;
    & > table {
      width: 100%;
      td, th {
        &:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          min-width: 260px;
          max-width: 400px;
          & + th, & + td {
            padding-left: $spacer * 1.5;
          }
        }
      }
    }
  }
  &.scrollable {
    &:after {
      @include pseudo-el();
      top: 0;
      right: 0;
      height: 100%;
      width: $spacer * 2;
      background-image: linear-gradient(to left, white, transparent);
    }
    .scrolltable-2 {
      & > table {
        td, th {
          &:first-child {
            &:before {
              @include pseudo-el();
              background-color: $color-gray-ice;
              width: 1px;
              height: 100%;
              left: 100%;
              top: 0;
            }
            &:after {
              @include pseudo-el();
              background-image: linear-gradient(to right, rgba(0,0,0,0.025), transparent);
              width: 12px;
              height: 100%;
              left: 100%;
              top: 0;
            }
          }
        }
      }
    }
  }
}

